import React from 'react';
import { Button, Icon, Modal, Table } from 'semantic-ui-react';
import Select from 'react-select';

const positions = ['QB', 'WR', 'TE', 'DST', 'RB'].map(i => {
    return { label: i, value: i };
});

const optional_positions = ['--', 'QB', 'WR', 'TE', 'DST', 'RB'].map(i => {
    return { label: i, value: i };
});

const optional_conjunctions = ['--', 'AND', 'OR'].map(i => {
    return { label: i, value: i };
});

interface StackingTableState {
    isOpen: boolean;
    numStacks: number;
    stackData: any[];
    stack1pos: any;
    stack2pos: any;
    stack3pos: any;
    stack4pos: any;
    conjunction1: any;
    conjunction2: any;
    teams: string[];
    teamStackNum: any;
}

class PositionStackTable extends React.Component<any, StackingTableState> {
    constructor(props: unknown) {
        super(props);

        this.submitStacks.bind(this);

        this.state = {
            isOpen: false,
            numStacks: 3,
            stackData: [],
            stack1pos: positions[0],
            stack2pos: positions[1],
            stack3pos: optional_positions[0],
            stack4pos: optional_positions[0],
            conjunction1: optional_conjunctions[0],
            conjunction2: optional_conjunctions[0],
            teams: [],
            teamStackNum: { value: 1, label: 1 },
        };
    }

    setOpen(val: boolean): void {
        this.setState({ isOpen: val });
    }

    submitStacks(): void {
        let stackString = '';

        if (this.getStackTypeName() === 'Position') {
            const stack1 = this.state.stack1pos.value;
            const stack2 = this.state.stack2pos.value;
            const conj1 = this.state.conjunction1.value;
            const stack3 = this.state.stack3pos.value;
            const conj2 = this.state.conjunction2.value;
            const stack4 = this.state.stack4pos.value;

            stackString = `${stack1} with ${stack2} ${conj1.toLowerCase()} ${stack3} ${conj2.toLowerCase()} ${stack4}`
                .replaceAll('--', '')
                .trim();
        } else {
            // Team Stacking
            stackString = `Force ${this.state.teamStackNum.value}`;
        }

        const stateStackData = [...this.state.stackData];
        const obj = {
            stack: stackString,
            teams: (this.state.teams.length === 0 ? this.props.slate : this.state.teams).map((i: any) => i.value),
        };

        stateStackData.push(obj);
        this.setState({ stackData: stateStackData });

        this.props.changeState(obj);

        // TODO reset state for new selections
        this.setOpen(false);
    }

    handleTeamStackNumChange(e: any) {
        this.setState({ teamStackNum: e });
    }

    // TODO convert to list and use index
    handleConjunctionChange(e: any, index: number): void {
        if (index === 0) {
            this.setState({ conjunction1: e });
        } else if (index === 1) {
            this.setState({ conjunction2: e });
        }
    }

    // TODO convert to list and use index
    handleStackChange(e: any, index: number): void {
        if (index === 0) {
            this.setState({ stack1pos: e });
        } else if (index === 1) {
            this.setState({ stack2pos: e });
        } else if (index === 2) {
            this.setState({ stack3pos: e });
        } else if (index === 3) {
            this.setState({ stack4pos: e });
        }
    }

    handleTeamChange(e: any): void {
        this.setState({ teams: e });
    }

    removeStack(idx: number): void {
        const stateStackData = [...this.state.stackData];
        const stackToRemove = stateStackData[idx];

        stateStackData.splice(idx, 1);

        this.props.removeStack(stackToRemove);

        this.setState({ stackData: stateStackData });
    }

    editPositionStack(event: any): void {
        this.setState({ isOpen: true });
    }

    getStackTypeName(): string {
        if (this.props.stackType === 'position') {
            return 'Position';
        }
        return 'Team';
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    size="mini"
                    open={this.state.isOpen}
                    trigger={
                        <Button
                            onClick={() => this.setState({ isOpen: true })}
                            icon
                            labelPosition="left"
                            size="mini"
                            color="green"
                        >
                            <Icon name="add" />
                            Add {this.getStackTypeName()} Stack
                        </Button>
                    }
                >
                    <Modal.Header>Add {this.getStackTypeName()} Stack</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {this.getStackTypeName() === 'Position' ? (
                                    <React.Fragment>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Stack&nbsp;
                                            <Select
                                                options={positions}
                                                value={this.state.stack1pos}
                                                onChange={e => this.handleStackChange(e, 0)}
                                            />
                                            &nbsp;with&nbsp;
                                            <Select
                                                options={positions}
                                                value={this.state.stack2pos}
                                                onChange={e => this.handleStackChange(e, 1)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <Select
                                                options={optional_conjunctions}
                                                value={this.state.conjunction1}
                                                onChange={e => this.handleConjunctionChange(e, 0)}
                                            />
                                            <Select
                                                options={optional_positions}
                                                value={this.state.stack3pos}
                                                onChange={e => this.handleStackChange(e, 2)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <Select
                                                options={optional_conjunctions}
                                                value={this.state.conjunction2}
                                                onChange={e => this.handleConjunctionChange(e, 1)}
                                            />
                                            <Select
                                                options={optional_positions}
                                                value={this.state.stack4pos}
                                                onChange={e => this.handleStackChange(e, 3)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Include&nbsp;
                                            <Select
                                                isMulti
                                                placeholder="All Teams"
                                                options={this.props.slate}
                                                value={this.state.teams}
                                                onChange={e => this.handleTeamChange(e)}
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                Force&nbsp;
                                                <Select
                                                    options={[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i: any) => {
                                                        return { label: i, value: i };
                                                    })}
                                                    value={this.state.teamStackNum}
                                                    onChange={e => this.handleTeamStackNumChange(e)}
                                                />
                                            </div>
                                            <div>
                                                &nbsp;Players from&nbsp;
                                                <Select
                                                    isMulti
                                                    placeholder="All Teams"
                                                    options={this.props.slate}
                                                    value={this.state.teams}
                                                    onChange={e => this.handleTeamChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setOpen(false)}>Cancel</Button>
                        <Button onClick={() => this.submitStacks()} positive>
                            Add
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Table compact celled unstackable selectable={this.state.stackData.length > 0}>
                    <Table.Body>
                        {this.state.stackData.length > 0 ? (
                            this.state.stackData.map((i, idx) => {
                                return (
                                    <Table.Row key={idx}>
                                        <Table.Cell
                                        // onClick={(event: any) => {
                                        //     this.editPositionStack(event);
                                        // }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                {i.stack} ({i.teams.join(', ')})
                                                <Icon
                                                    style={{ float: 'right' }}
                                                    name="x"
                                                    color="red"
                                                    onClick={() => this.removeStack(idx)}
                                                />
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })
                        ) : (
                            <>
                                <Table.Row>
                                    <Table.Cell>
                                        You currently have no {this.getStackTypeName().toLowerCase()} stacks.
                                    </Table.Cell>
                                </Table.Row>
                            </>
                        )}
                    </Table.Body>
                </Table>
            </React.Fragment>
        );
    }
}

export default PositionStackTable;
