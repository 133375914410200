import React, { useEffect, useState } from 'react';
import { Segment, Button, Grid, List, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { DataService } from '../../shared/services/data.service';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import Globals from '../../shared/globals';
import { NotFound } from '../../shared/components/notFound';

interface IBuildHistory {
    site: string;
    sport: string;
    timeStamp: number;
    success: boolean;
}

function DashboardParent(props: any) {
    const { name } = props;

    return <React.Fragment>{Globals.AdminUsers.includes(name) ? <Dashboard /> : <NotFound />}</React.Fragment>;
}

function Dashboard() {
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [isLoading4, setIsLoading4] = useState(false);
    const [buildHistoryList, setBuildHistoryList] = useState<IBuildHistory[]>([]);

    useEffect(() => {
        DataService.genericGET('/build_history').then(i => {
            // TODO error handling
            const buildHistory: IBuildHistory[] = i.data.build_history.map((x: any) => {
                return {
                    site: x.site,
                    sport: x.sport,
                    timeStamp: x.build_time,
                    success: x.build_result === 'success',
                };
            });

            setBuildHistoryList(buildHistory);
        });
    }, []);

    function handleBuildButton(button: number) {
        let setLoading: any;
        let site: 'DraftKings' | 'FanDuel' = 'DraftKings';
        let sport: 'NFL' | 'NBA' = 'NFL';
        let shortSite: 'dk' | 'fd' = 'dk';

        if (button === 1) {
            site = 'DraftKings';
            shortSite = 'dk';
            sport = 'NFL';
            setLoading = setIsLoading1;
        } else if (button === 2) {
            site = 'FanDuel';
            shortSite = 'fd';
            sport = 'NFL';
            setLoading = setIsLoading2;
        } else if (button === 3) {
            site = 'DraftKings';
            shortSite = 'dk';
            sport = 'NBA';
            setLoading = setIsLoading3;
        } else if (button === 4) {
            site = 'FanDuel';
            shortSite = 'fd';
            sport = 'NBA';
            setLoading = setIsLoading4;
        }

        setLoading(true);

        const oldBuildHistory: any[] = buildHistoryList;

        DataService.genericGET(`/${shortSite}/${sport.toLowerCase()}/build`).then(i => {
            oldBuildHistory.unshift({
                site: site.toLowerCase(),
                sport: sport.toLowerCase(),
                timeStamp: moment.unix(new Date().getTime() / 1000),
                success: i.data.success,
            });
            setBuildHistoryList(oldBuildHistory);
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div style={{ padding: '15px' }}>
                <Segment>
                    <h4>Build</h4>
                    <Grid stackable relaxed columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <Button.Group widths="2">
                                    <Button loading={isLoading1} onClick={() => handleBuildButton(1)}>
                                        DraftKings NFL
                                    </Button>
                                    <Button.Or />
                                    <Button loading={isLoading2} onClick={() => handleBuildButton(2)}>
                                        FanDuel NFL
                                    </Button>
                                </Button.Group>
                            </Grid.Column>
                            <Grid.Column>
                                <Button.Group widths="2">
                                    <Button loading={isLoading3} onClick={() => handleBuildButton(3)}>
                                        DraftKings NBA
                                    </Button>
                                    <Button.Or />
                                    <Button loading={isLoading4} onClick={() => handleBuildButton(4)}>
                                        FanDuel NBA
                                    </Button>
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <h4>Build History</h4>
                    <List celled>
                        {buildHistoryList.map((b, idx) => {
                            return (
                                <List.Item key={idx}>
                                    <List.Content verticalAlign="top">
                                        <List.Header>
                                            {' '}
                                            {b.success ? (
                                                <Icon name="check circle outline" color="green" />
                                            ) : (
                                                <Icon name="times circle outline" color="red" />
                                            )}
                                            {`${b.site} ${b.sport}`}
                                        </List.Header>
                                        {moment(b.timeStamp).format('ddd, MMM Do, YYYY, h:mm:ss A')}
                                    </List.Content>
                                </List.Item>
                            );
                        })}
                    </List>
                </Segment>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state: any) => {
    return {
        name: state.authState.name,
    };
};

const ConnectedDashboard = connect(mapStateToProps, undefined)(DashboardParent);

export const DashboardContainer = () => (
    <DynamicModuleLoader modules={[]}>
        <ConnectedDashboard></ConnectedDashboard>
    </DynamicModuleLoader>
);
