import React from 'react';
import { IconButton, Divider, Drawer, List, ListItem, MuiThemeProvider } from '@material-ui/core';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useStyles } from '../styles/layout';
import { LayoutModule } from '../module/module';
import { LayoutRootType } from '../types';
import { connect } from 'react-redux';
import { toggleSidebar } from '../actions/actions';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { sidebarTheme } from '../styles/sidebarTheme';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useLocation } from 'react-router-dom';
import { fetchLogoutAsync } from '../../../../shared/auth/actions/actions';
import atswhitelogo from '../../../../static/atswhitelogo.png';
import Globals from '../../../../shared/globals';

interface SidebarProps {
    open: boolean;
    toggleOpen: typeof toggleSidebar;
    name: string;
    logout: typeof fetchLogoutAsync.request;
}

function Sidebar(props: SidebarProps) {
    const { open, toggleOpen, logout } = props;
    const classes = useStyles();
    const toggleDrawer = () => {
        toggleOpen();
    };
    const location = useLocation();

    const menus = (
        <React.Fragment>
            <ListItem style={{ height: 48 }}>
                <div className={classes.toolbar}>
                    <img
                        alt="ATS Logo (white)"
                        src={atswhitelogo}
                        style={{ width: '30px', height: '30px', marginRight: '8px' }}
                    />
                    <ListItemText primary="All Things Sports" />
                    <IconButton onClick={toggleDrawer} style={{ color: 'white' }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
            </ListItem>
            <Divider />
            <List>
                {/* <ListItemButton href="#" selected={location.pathname == '/app/profile'}>
                    <ListItemText primary="My Profile" />
                </ListItemButton> */}
                {Globals.AdminUsers.includes(props.name) ? (
                    <>
                        <ListItemButton href="/app/admin" selected={location.pathname == '/app/admin'}>
                            <ListItemText primary="Administrator Dashboard" />
                        </ListItemButton>
                        <Divider />
                    </>
                ) : (
                    <></>
                )}
                <ListItemButton href="/app/nfl/draftkings" selected={location.pathname == '/app/nfl/draftkings'}>
                    <ListItemText primary="DraftKings NFL Lineup Optimizer" />
                </ListItemButton>
                <ListItemButton href="/app/nfl/fanduel" selected={location.pathname == '/app/nfl/fanduel'}>
                    <ListItemText primary="FanDuel NFL Lineup Optimizer" />
                </ListItemButton>
                <Divider />
                <ListItemButton href="/app/nba/draftkings" selected={location.pathname == '/app/nba/draftkings'}>
                    <ListItemText primary="DraftKings NBA Lineup Optimizer" />
                </ListItemButton>
                <ListItemButton href="/app/nba/fanduel" selected={location.pathname == '/app/nba/fanduel'}>
                    <ListItemText primary="FanDuel NBA Lineup Optimizer" />
                </ListItemButton>
                {/* <Divider />
                <ListItemButton href="#" selected={location.pathname == '/app/stats'}>
                    <ListItemText primary="Stats Tables" />
            </ListItemButton>*/}
                <Divider />
                <ListItemButton onClick={logout}>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <MuiThemeProvider theme={sidebarTheme}>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    open={open}
                >
                    {menus}
                </Drawer>
            </MuiThemeProvider>
        </React.Fragment>
    );
}

const mapStateToProps = (state: LayoutRootType) => {
    return {
        name: state.authState.name,
        open: state.layoutState.sidbarOpened,
    };
};

const dispatchToProps = {
    toggleOpen: toggleSidebar,
    logout: fetchLogoutAsync.request,
};

const ConnectedSidebar = connect(mapStateToProps, dispatchToProps)(Sidebar);

export const SidebarContainer = () => (
    <DynamicModuleLoader modules={[LayoutModule]}>
        <ConnectedSidebar></ConnectedSidebar>
    </DynamicModuleLoader>
);
