import React from 'react';
import { useStyles } from '../styles/layout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MainContainer(props: React.PropsWithChildren<any>) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.container}>{props.children}</div>
            </main>
        </React.Fragment>
    );
}
