/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select';
import { Button, Confirm, Divider, Input, Icon, Tab, Table, Message } from 'semantic-ui-react';
import Globals from '../../../shared/globals';
import { CSVLink } from 'react-csv';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { orderBy } from 'lodash';
import { LoadingComponent } from '../../../shared/components/loading';
import { connect } from 'react-redux';
import { fetchLogoutAsync } from '../../../shared/auth/actions/actions';
import { AuthModule } from '../../../shared/auth/module/module';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { useParams } from 'react-router-dom';
import { DataService, APIPath } from '../../../shared/services/data.service';
import PositionStackTable from './stacking/stackingTableComponent';

interface test {
    stack: string;
    teams: string[];
}

interface inputOptimizerComponentProps {
    site?: string;
    sport?: string;
    logout: typeof fetchLogoutAsync.request;
}

interface inputOptimizerComponentState {
    isLoading: boolean;
    hasErrors: boolean;
    errorMessage: string;
    teams: any[];
    selectedTeams: any[];
    origTeams: any[];
    positions: any[];
    selectedPosition: string;
    lockedPlayers: any[];
    removedPlayers: any[];
    currentPlayers: any[];
    tablePlayerData: any[];
    outputData: any[];
    numBuilds: number;
    slates: any[];
    open: boolean;
    sortColumn: string;
    sortDirection: 'descending' | 'ascending' | undefined;
    lastUpdatedTimestamp: string;
    playerSearch: string;
    positionStackStrings: test[];
    // activeTab: 'all' | 'included' | 'excluded';
}

class InputOptimizerComponent extends React.Component<inputOptimizerComponentProps, inputOptimizerComponentState> {
    myRef: React.RefObject<HTMLDivElement>;

    constructor(props: inputOptimizerComponentProps) {
        super(props);

        this.myRef = React.createRef();
        this.setPositionStackState.bind(this);
        this.removeStack.bind(this);

        this.state = {
            isLoading: true,
            hasErrors: false,
            errorMessage: '',
            teams: [],
            selectedTeams: [],
            origTeams: [],
            positions: [],
            selectedPosition: 'All',
            lockedPlayers: [],
            removedPlayers: [],
            currentPlayers: [],
            tablePlayerData: [],
            outputData: [],
            numBuilds: 1,
            slates: [],
            open: false,
            sortColumn: 'pps',
            sortDirection: 'descending',
            lastUpdatedTimestamp: '',
            playerSearch: '',
            positionStackStrings: [],
            // activeTab: 'all',
        };
    }

    componentDidMount(): void {
        let urlString = '';

        // TODO need a better way of getting these strings
        if (this.props.site === 'draftkings') {
            if (this.props.sport === 'nba') urlString = APIPath.getNbaDkPlayers;
            else urlString = APIPath.getNflDkPlayers;
        } else if (this.props.site === 'fanduel') {
            if (this.props.sport === 'nfl') urlString = APIPath.getNflFdPlayers;
            else urlString = APIPath.getNbaFdPlayers;
        }

        DataService.genericGET(urlString)
            .then((response: any) => {
                const data = response.data;

                data.player_lineup.forEach((i: any) => {
                    i['locked'] = false;
                    i['minExposure'] = 0;
                    i['maxExposure'] = 100;
                    i['pps'] = (i.fppg / (i.salary / 1000)).toFixed(2);
                });

                // Add All pos filter
                data.positions.unshift('All');

                // TODO pull this from default state
                const defaultSortPlayers = orderBy(data.player_lineup, ['pps'], ['desc']);

                this.setState({
                    isLoading: false,
                    teams: data.teams.map((i: any) => ({ label: i, value: i })),
                    origTeams: data.teams,
                    currentPlayers: defaultSortPlayers,
                    tablePlayerData: defaultSortPlayers,
                    positions: data.positions,
                    slates: data.slates,
                    lastUpdatedTimestamp: data.last_updated_date
                        ? moment(data.last_updated_date).format('ddd, MMM Do, YYYY, h:mm:ss A')
                        : 'no data',
                });
            })
            .catch(error => {
                this.setState({
                    hasErrors: true,
                    errorMessage:
                        'An unknown error has occurred. Please try your request again or contact an administrator for assistance.',
                });
                const { response }: { response: AxiosResponse } = error;
                if (response.status === 401) {
                    this.props.logout();
                }
            });
    }

    setPositionStackState = (newValue: test) => {
        const positionStackStrings: test[] = [...this.state.positionStackStrings];
        positionStackStrings.push(newValue);

        this.setState({ positionStackStrings });
    };

    removeStack = (stack: test) => {
        let positionStackStrings: test[] = [...this.state.positionStackStrings];
        positionStackStrings = positionStackStrings.filter(i => i !== stack);

        this.setState({ positionStackStrings });
    };

    unExcludePlayer(player: any) {
        const tablePlayerIdx = this.state.removedPlayers.findIndex(i => i.name === player.name);

        const removedPlayers = [...this.state.removedPlayers];
        const excludePlayer = { ...removedPlayers[tablePlayerIdx] };
        excludePlayer.maxExposure = 100;
        removedPlayers.splice(tablePlayerIdx, 1);

        let currentPlayers = [...this.state.currentPlayers];
        currentPlayers.push(excludePlayer);

        // TODO need to fix this sort and pull automatically
        let sortDirection: 'asc' | 'desc' = 'desc';
        if (this.state.sortDirection === 'ascending') {
            sortDirection = 'asc';
        } else if (this.state.sortDirection === 'descending') {
            sortDirection = 'desc';
        }

        currentPlayers = orderBy(currentPlayers, this.state.sortColumn, sortDirection);

        this.setState({ currentPlayers, tablePlayerData: currentPlayers, removedPlayers });
    }

    excludePlayer(props: any) {
        const tablePlayerIdx = this.state.tablePlayerData.findIndex(i => i.name === props.name);

        // Remove player from lockedPlayers
        const lockedPlayerIdx = this.state.lockedPlayers.findIndex(i => i.name === props.name);

        if (lockedPlayerIdx >= 0) {
            const lockedPlayers = [...this.state.lockedPlayers];
            lockedPlayers.splice(lockedPlayerIdx, 1);
            this.setState({ lockedPlayers });
        }

        // Remove player from currentTable
        const tablePlayerData = [...this.state.tablePlayerData];
        tablePlayerData.splice(tablePlayerIdx, 1);

        // Remove player from currentPlayers
        const currentPlayerIdx = this.state.currentPlayers.findIndex(i => i.name === props.name);

        const currentPlayers = [...this.state.currentPlayers];
        const currentPlayer = { ...currentPlayers[currentPlayerIdx] };
        currentPlayer.locked = false;
        currentPlayer.maxExposure = 0;
        currentPlayer.minExposure = 0;
        currentPlayers.splice(currentPlayerIdx, 1);

        // Keep track of removed players to send to opto
        const removedPlayers = [...this.state.removedPlayers];
        removedPlayers.push(currentPlayer);

        this.setState({ currentPlayers, removedPlayers, tablePlayerData });
    }

    toggleLock(props: any) {
        // Update current table data
        const tablePlayerIdx = this.state.tablePlayerData.findIndex(i => i.name === props.name);

        const tablePlayerData = [...this.state.tablePlayerData];
        const tablePlayer = { ...tablePlayerData[tablePlayerIdx] };
        tablePlayer.locked = !tablePlayer.locked;
        tablePlayerData[tablePlayerIdx] = tablePlayer;

        // Update actual player data
        const currentPlayerIdx = this.state.currentPlayers.findIndex(i => i.name === props.name);

        const currentPlayers = [...this.state.currentPlayers];
        const currentPlayer = { ...currentPlayers[currentPlayerIdx] };
        currentPlayer.locked = !currentPlayer.locked;
        currentPlayers[currentPlayerIdx] = currentPlayer;

        // Keep track of locked players to send to opto
        const lockedPlayers = [...this.state.lockedPlayers];
        if (currentPlayer.locked) {
            lockedPlayers.push(currentPlayer);
        } else {
            const lockedPlayerIdx = lockedPlayers.findIndex(i => i.name === props.name);
            lockedPlayers.splice(lockedPlayerIdx, 1);
        }

        this.setState({ currentPlayers, tablePlayerData, lockedPlayers });
    }

    handlePosFilter(positions: any) {
        let currentPlayers: any[] = [];

        currentPlayers = [...this.state.currentPlayers];

        // If there are selected teams, filter on them
        if (this.state.selectedTeams.length > 0) {
            currentPlayers = currentPlayers.filter(i => this.state.selectedTeams.map(i => i.value).includes(i.team));
        }

        const filterValue = positions[0];

        if (filterValue === 'All' || filterValue === undefined) {
            this.setState({ tablePlayerData: currentPlayers, selectedPosition: filterValue });
        } else if (filterValue === 'Flex') {
            // ONLY supported by NFL which only has 1 position so don't need to loop like case below
            const filteredPlayers = currentPlayers.filter(
                i => i.positions[0] === 'RB' || i.positions[0] === 'WR' || i.positions[0] === 'TE',
            );
            this.setState({ tablePlayerData: filteredPlayers, selectedPosition: filterValue });
        } else {
            // TODO loop through and handle all positions instead of hardcoding 0 and 1 idx
            const filteredPlayers = currentPlayers.filter(
                i => filterValue === i.positions[0] || filterValue === i.positions[1],
            );
            this.setState({ tablePlayerData: filteredPlayers, selectedPosition: filterValue });
        }
    }

    handleTeamFilter(teams: any, isSlate: boolean) {
        let currentPlayers: any[] = [];

        currentPlayers = [...this.state.currentPlayers];

        // Check if position is filtered and filter on it
        if (this.state.selectedPosition !== 'All') {
            currentPlayers = currentPlayers.filter(
                i => this.state.selectedPosition === i.positions[0] || this.state.selectedPosition === i.positions[1],
            );
        }

        if (teams.length === 0) {
            this.setState({ selectedTeams: teams, tablePlayerData: currentPlayers });
        } else {
            const filteredPlayers = currentPlayers.filter(i => teams.map((i: any) => i.value).includes(i.team));

            // Refresh options
            if (isSlate) {
                this.setState({ teams });
            }
            this.setState({ selectedTeams: teams, tablePlayerData: filteredPlayers });
        }
    }

    handleNumBuildChange = (event: any) => {
        this.setState({ numBuilds: parseInt(event.target.value) });
    };

    submitOptimizer() {
        // ReactGA.initialize('G-J0WR9VPTGL');
        // ReactGA.event({
        //     category: 'Submits',
        //     action: 'Submit Optimizer',
        // });

        this.setState({
            // isLoading: true,
            hasErrors: false,
            errorMessage: '',
            outputData: [],
        });

        const dto = {
            lockedPlayers: this.state.lockedPlayers,
            removedPlayers: this.state.removedPlayers,
            exposurePlayers: this.state.currentPlayers.filter(x => x.minExposure !== 0 || x.maxExposure !== 100),
            teamsToExclude:
                this.state.origTeams.filter(x => !this.state.selectedTeams.map(i => i.value).includes(x)).length ===
                this.state.origTeams.length
                    ? []
                    : this.state.origTeams.filter(x => !this.state.selectedTeams.map(i => i.value).includes(x)),
            nVal: this.state.numBuilds,
            posStackData: this.state.positionStackStrings,
        };

        let urlDfsSiteString = '';
        if (this.props.site === 'draftkings') {
            urlDfsSiteString = '/dk';
        } else if (this.props.site === 'fanduel') {
            urlDfsSiteString = '/fd';
        }

        // TODO maybe have a better way of handling this when adding more sports/dfs sites
        const submitString = urlDfsSiteString + (this.props.sport === 'nba' ? '/nba' : '/nfl') + '/optimize';

        DataService.genericPOST(submitString, dto)
            .then(response => {
                if (response.data.errors) {
                    this.setState({
                        // isLoading: false,
                        hasErrors: response.data.errors,
                        errorMessage: response.data.error_message,
                    });
                } else {
                    this.setState({
                        // isLoading: false,
                        outputData: response.data,
                    });

                    if (this.myRef.current) {
                        this.myRef.current.scrollIntoView();
                    }
                }
            })
            .catch(error => {
                this.setState({
                    hasErrors: true,
                    errorMessage:
                        'An unknown error has occurred. Please try your request again or contact an administrator for assistance.',
                });
                console.error(error);
            });
    }

    exportCsv() {
        const dfsSite = this.props.site;
        const sport = this.props.sport;

        const csvArray = [];

        if (dfsSite === 'draftkings') {
            let lineArray: any[][] = [];
            if (sport === 'nfl') {
                // Write CSV header depending on sport
                csvArray.push(['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DST', 'Budget', 'FPPG']);
            } else if (sport === 'nba') {
                csvArray.push(['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL', 'Budget', 'FPPG']);
            }
            this.state.outputData.forEach(i => {
                lineArray.push(i.player_lineup.map((x: any) => `${x.name}(${x.pid})`));
                lineArray.push([i.salary_costs.toFixed(1), i.fantasy_points_projection]);
                csvArray.push(lineArray);
                lineArray = [];
            });
        } else if (dfsSite === 'fanduel') {
            // Write CSV header depending on sport
            if (sport === 'nfl') {
                csvArray.push(['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DEF']);
            } else if (sport === 'nba') {
                csvArray.push(['PG', 'PG', 'SG', 'SG', 'SF', 'SF', 'PF', 'PF', 'C']);
            }
            this.state.outputData.forEach(i => {
                csvArray.push(i.player_lineup.map((x: any) => x.pid));
            });
        }

        return csvArray;
    }

    handleConfirm() {
        window.location.reload();
        // this.setState({ open: false, selectedPosition: 'All', selectedTeams: [], removedPlayers: [] });
    }

    handleCancel() {
        this.setState({ open: false });
    }

    handleExposureChange(event: any, props: any, type: any) {
        let newVal = event.target.valueAsNumber;

        if (isNaN(newVal)) {
            newVal = 0;
        } else if (newVal < 0) {
            newVal = 0;
        } else if (newVal > 100) {
            newVal = 100;
        }

        const tablePlayerIdx = this.state.tablePlayerData.findIndex(i => i.name === props.name);
        const tablePlayerData = [...this.state.tablePlayerData];
        const tablePlayer = { ...tablePlayerData[tablePlayerIdx] };

        const currentPlayerIdx = this.state.currentPlayers.findIndex(i => i.name === props.name);
        const currentPlayers = [...this.state.currentPlayers];
        const currentPlayer = { ...currentPlayers[currentPlayerIdx] };

        if (type === 'max') {
            currentPlayer.maxExposure = newVal;
            tablePlayer.maxExposure = newVal;
        } else if (type === 'min') {
            currentPlayer.minExposure = newVal;
            tablePlayer.minExposure = newVal;
        }

        currentPlayers[currentPlayerIdx] = currentPlayer;
        tablePlayerData[tablePlayerIdx] = tablePlayer;
        this.setState({ currentPlayers, tablePlayerData });
    }

    fixErrorMessage(errorMessage: string) {
        return errorMessage.replace("ou're", "ou've").replace('select', 'selected');
    }

    handleTableSort(column: string) {
        let direction: 'asc' | 'desc' = 'desc';

        if (this.state.sortColumn !== column) {
            this.setState({ sortDirection: 'descending' });
        } else {
            if (this.state.sortDirection === 'ascending') {
                this.setState({ sortDirection: 'descending' });
                direction = 'desc';
            } else if (this.state.sortDirection === 'descending') {
                this.setState({ sortDirection: 'ascending' });
                direction = 'asc';
            }
        }

        let tablePlayerData = [...this.state.tablePlayerData];
        tablePlayerData = orderBy(tablePlayerData, [column], [direction]);

        this.setState({ tablePlayerData, sortColumn: column });
    }

    handlePlayerSearch(e: any) {
        const playerSearch = e.target.value.toLowerCase();
        this.setState({ playerSearch });
    }

    clearPlayerSearch() {
        this.setState({ playerSearch: '' });
    }

    SubTableComponent(tableTitle: 'all' | 'included' | 'excluded') {
        let tableData: any;

        if (tableTitle === 'all') {
            tableData = this.state.tablePlayerData;
        } else if (tableTitle === 'included') {
            tableData = this.state.lockedPlayers;
        } else if (tableTitle === 'excluded') {
            tableData = this.state.removedPlayers;
        }

        // filter for player search, can possibly handle sort players here too?
        tableData = tableData.filter((i: any) => i.name.toLowerCase().includes(this.state.playerSearch));

        return (
            <Tab.Pane attached={true}>
                {this.state.hasErrors ? (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p style={{ overflowWrap: 'break-word' }}>{this.fixErrorMessage(this.state.errorMessage)}</p>
                    </Message>
                ) : (
                    <></>
                )}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ margin: '1rem', minWidth: 300 }}>
                        <Input
                            fluid
                            size="small"
                            icon={
                                this.state.playerSearch === '' ? (
                                    <Icon name="search" />
                                ) : (
                                    <Icon name="x" onClick={() => this.clearPlayerSearch()} link />
                                )
                            }
                            placeholder="Search for a player..."
                            value={this.state.playerSearch}
                            onChange={e => this.handlePlayerSearch(e)}
                        />
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <Button.Group size="small">
                            {this.state.positions.map((b, i) => {
                                return (
                                    <Button
                                        disabled={tableTitle === 'included' || tableTitle === 'excluded'}
                                        compact
                                        key={i}
                                        active={this.state.selectedPosition === b}
                                        onClick={() => this.handlePosFilter([b])}
                                    >
                                        {b}
                                    </Button>
                                );
                            })}
                            {Globals.AdditionalFilters.map((b, i) => {
                                if (this.props.sport?.toUpperCase() === b.sport) {
                                    return (
                                        <Button
                                            disabled={tableTitle === 'included' || tableTitle === 'excluded'}
                                            compact
                                            key={i}
                                            active={this.state.selectedPosition === b.filterName}
                                            onClick={() => this.handlePosFilter([b.filterName])}
                                        >
                                            {b.filterName}
                                        </Button>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Button.Group>
                    </div>
                </div>
                <div style={{ maxHeight: '500px', overflowY: 'scroll', overflowX: 'scroll' }}>
                    <Table unstackable fixed sortable celled striped compact collapsing style={{ margin: 0 }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={this.state.sortColumn === 'name' ? this.state.sortDirection : undefined}
                                    onClick={() => this.handleTableSort('name')}
                                >
                                    Player
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={this.state.sortColumn === 'team' ? this.state.sortDirection : undefined}
                                    onClick={() => this.handleTableSort('team')}
                                >
                                    Team
                                </Table.HeaderCell>
                                <Table.HeaderCell>Pos</Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={this.state.sortColumn === 'salary' ? this.state.sortDirection : undefined}
                                    onClick={() => this.handleTableSort('salary')}
                                >
                                    Salary
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={this.state.sortColumn === 'pps' ? this.state.sortDirection : undefined}
                                    onClick={() => this.handleTableSort('pps')}
                                >
                                    P/S
                                </Table.HeaderCell>
                                <Table.HeaderCell>Mn%</Table.HeaderCell>
                                <Table.HeaderCell>Mx%</Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={this.state.sortColumn === 'fppg' ? this.state.sortDirection : undefined}
                                    onClick={() => this.handleTableSort('fppg')}
                                >
                                    Points
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {tableData.length > 0 ? (
                            <Table.Body>
                                {tableData.map((p: any, i: number) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    {tableTitle !== 'excluded' ? (
                                                        <Icon
                                                            onClick={() => this.excludePlayer(p)}
                                                            style={{ cursor: 'pointer' }}
                                                            color="red"
                                                            name="x"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            onClick={() => this.unExcludePlayer(p)}
                                                            style={{ cursor: 'pointer' }}
                                                            color="green"
                                                            name="checkmark"
                                                        />
                                                    )}
                                                    {tableTitle === 'excluded' ? (
                                                        <></>
                                                    ) : p.locked ? (
                                                        <Icon
                                                            onClick={() => this.toggleLock(p)}
                                                            style={{ cursor: 'pointer' }}
                                                            color="green"
                                                            name="lock"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            onClick={() => this.toggleLock(p)}
                                                            style={{ cursor: 'pointer' }}
                                                            color="grey"
                                                            name="unlock"
                                                        />
                                                    )}
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b style={{ color: '#2185d0' }}>{p.name}</b>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b>{p.team}</b>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b>{p.positions.join(' ')}</b>
                                            </Table.Cell>
                                            <Table.Cell>${p.salary.toLocaleString()}</Table.Cell>
                                            <Table.Cell>{p.pps}</Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    onChange={e => this.handleExposureChange(e, p, 'min')}
                                                    size="mini"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    step="0.5"
                                                    value={p.minExposure}
                                                    disabled={p.locked || tableTitle === 'excluded'}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    onChange={e => this.handleExposureChange(e, p, 'max')}
                                                    size="mini"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    step="0.5"
                                                    value={p.maxExposure}
                                                    disabled={p.locked || tableTitle === 'excluded'}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{parseFloat(p.fppg.toFixed(2))}</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        ) : (
                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="9">No players.</Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        )}
                    </Table>
                </div>
            </Tab.Pane>
        );
    }

    TableComponent() {
        const panes = [
            {
                menuItem: `All Players (${this.state.tablePlayerData.length})`,
                render: () => this.SubTableComponent('all'),
            },
            {
                menuItem: `Included (${this.state.lockedPlayers.length})`,
                render: () => this.SubTableComponent('included'),
            },
            {
                menuItem: `Excluded (${this.state.removedPlayers.length})`,
                render: () => this.SubTableComponent('excluded'),
            },
        ];

        return (
            <>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '1rem',
                            marginBottom: '1rem',
                        }}
                    >
                        <h3>
                            <b>
                                {this.props.site?.toUpperCase()} {this.props.sport?.toUpperCase()} LINEUP OPTIMIZER
                            </b>
                        </h3>
                        <div>
                            <i>Projections Last Updated:</i> {this.state.lastUpdatedTimestamp}
                        </div>
                    </div>
                    <div style={{ margin: 'auto', maxWidth: 500 }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#f2f2f2',
                                border: '1px solid #ddd',
                                padding: '15px',
                                marginBottom: '1rem',
                            }}
                        >
                            <Input
                                label="Lineups"
                                labelPosition="right"
                                placeholder="# Build Lineups"
                                onChange={this.handleNumBuildChange}
                                type="number"
                                min="1"
                                max="25"
                                defaultValue="1"
                                style={{ marginBottom: '1rem' }}
                            />
                            <Button
                                primary
                                style={{ margin: 0 }}
                                onClick={() => this.submitOptimizer()}
                                disabled={this.state.isLoading}
                            >
                                Generate Lineups
                            </Button>
                        </div>
                        <div
                            style={{
                                background: '#f2f2f2',
                                border: '1px solid #ddd',
                                padding: '15px',
                                marginBottom: '1rem',
                            }}
                        >
                            <div style={{ marginBottom: '1rem' }}>
                                <div>Slate</div>
                                <Select
                                    isClearable
                                    className="slate-dropdown"
                                    placeholder="Select a slate..."
                                    options={this.state.slates.map(i => ({ label: i.slate, value: i.teams }))}
                                    onChange={e =>
                                        e
                                            ? this.handleTeamFilter(
                                                  e.value.map((i: any) => ({ label: i, value: i })),
                                                  true,
                                              )
                                            : this.handleTeamFilter([], true)
                                    }
                                />
                            </div>
                            <div style={{ marginBottom: '1rem' }}>
                                <div>Teams</div>
                                <Select
                                    isMulti
                                    className="team-dropdown"
                                    options={this.state.teams}
                                    placeholder="Select teams..."
                                    value={this.state.selectedTeams}
                                    onChange={e => this.handleTeamFilter(e, false)}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button style={{ margin: 0 }} onClick={() => this.setState({ open: true })}>
                                    Reset
                                </Button>
                            </div>
                            <Confirm
                                open={this.state.open}
                                content="Are you sure you want to reset?"
                                cancelButton="Never mind"
                                confirmButton="Let's do it"
                                onCancel={() => this.handleCancel()}
                                onConfirm={() => this.handleConfirm()}
                            />
                        </div>
                        <div
                            style={{
                                background: '#f2f2f2',
                                border: '1px solid #ddd',
                                padding: '15px',
                                marginBottom: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <PositionStackTable
                                slate={
                                    this.state.selectedTeams.length === 0 ? this.state.teams : this.state.selectedTeams
                                }
                                stackType="position"
                                changeState={this.setPositionStackState}
                                removeStack={this.removeStack}
                            />
                            <PositionStackTable
                                slate={
                                    this.state.selectedTeams.length === 0 ? this.state.teams : this.state.selectedTeams
                                }
                                stackType="team"
                                changeState={this.setPositionStackState}
                                removeStack={this.removeStack}
                            />
                        </div>
                    </div>
                </div>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoading ? <LoadingComponent /> : this.TableComponent()}
                <div ref={this.myRef}></div>
                {this.state.outputData.length > 0 ? (
                    <React.Fragment>
                        <Divider />
                        <CSVLink
                            data={this.exportCsv()}
                            enclosingCharacter={``}
                            filename={`ats_${this.props.site}_${this.props.sport}_${moment(new Date()).format(
                                'MMDDYY_hmmss',
                            )}.csv`}
                        >
                            <Button style={{ marginBottom: '14px', marginLeft: '14px' }} variant="secondary">
                                <Icon name="download" /> Export
                            </Button>
                        </CSVLink>
                    </React.Fragment>
                ) : (
                    <></>
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap', flexBasis: '100%', justifyContent: 'center' }}>
                    {this.state.outputData.map(function(object, i) {
                        return (
                            <React.Fragment key={i}>
                                <div style={{ margin: '8px' }}>
                                    <Table collapsing unstackable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Player</Table.HeaderCell>
                                                <Table.HeaderCell>Team</Table.HeaderCell>
                                                <Table.HeaderCell>Pos</Table.HeaderCell>
                                                <Table.HeaderCell>Salary</Table.HeaderCell>
                                                <Table.HeaderCell>Points</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {object.player_lineup.map((o: any, idx: any) => {
                                                return (
                                                    <Table.Row key={idx}>
                                                        <Table.Cell>
                                                            <b>{o.name}</b>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <b>{o.team}</b>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <b>{o.lineup_position}</b>
                                                        </Table.Cell>
                                                        <Table.Cell>${o.salary.toLocaleString()}</Table.Cell>
                                                        <Table.Cell>{parseFloat(o.fppg.toFixed(2))}</Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <b>Lineup {i + 1}</b>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell />
                                                <Table.HeaderCell>
                                                    <b>${object.salary_costs.toLocaleString()}</b>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <b>{object.fantasy_points_projection.toFixed(2)}</b>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }
}

const dispatchToProps = {
    logout: fetchLogoutAsync.request,
};

const ConnectedInputOptimizer = connect(undefined, dispatchToProps)(InputOptimizerComponent);

export function InputOptimizerContainer() {
    const { dfsSite, sport } = useParams();

    return (
        <DynamicModuleLoader modules={[AuthModule]}>
            <ConnectedInputOptimizer site={dfsSite} sport={sport}></ConnectedInputOptimizer>
        </DynamicModuleLoader>
    );
}
