import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import * as actionTypes from '../actions/actions';
import * as api from '../api/authapi';
import { AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* loginRequest(action: ReturnType<typeof actionTypes.fetchLoginAsync.request>): Generator {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = yield call(api.login, action.payload.username, action.payload.password);

        if (response) {
            const token = response.data.data.token;
            const displayName = response.data.data.displayName;

            localStorage.setItem('wp_tok', token);
            localStorage.setItem('displayName', displayName);

            yield put(
                actionTypes.fetchLoginAsync.success({
                    name: displayName,
                    isLoggedIn: true,
                    message: '',
                }),
            );
        }
    } catch (e) {
        // TODO display different errors for locked out and invalid password
        // const { response }: { response: AxiosResponse } = e;
        yield put(actionTypes.fetchLoginAsync.failure('Invalid username or password.'));
    }
}

function* logoutRequest() {
    try {
        localStorage.removeItem('displayName');
        localStorage.removeItem('authToken');
        yield put(actionTypes.fetchLogoutAsync.success(true));
    } catch (e) {
        yield put(actionTypes.fetchLogoutAsync.failure(e));
    }
}

function* login() {
    yield takeLatest(actionTypes.fetchLoginAsync.request, loginRequest);
}
function* logout() {
    yield takeLatest(actionTypes.fetchLogoutAsync.request, logoutRequest);
}

export default function* authSaga() {
    yield all([fork(login), fork(logout)]);
}
