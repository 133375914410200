import React from 'react';
import { Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from '../styles/layout';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import { LayoutModule } from '../module/module';
import { toggleSidebar } from '../actions/actions';
import { LayoutRootType } from '../types';
import atswhitelogo from '../../../../static/atswhitelogo.png';

interface NavProps {
    open: boolean;
    toggleOpen: () => void;
    name: string | null;
}

function Navbar(props: NavProps) {
    const { open, toggleOpen, name } = props;
    const classes = useStyles();
    const toggleDrawer = () => {
        toggleOpen();
    };
    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                    })}
                >
                    <MenuIcon />
                </IconButton>
                <div
                    className={clsx(classes.title, {
                        [classes.hide]: open,
                    })}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            alt="ATS Logo (white)"
                            src={atswhitelogo}
                            style={{ width: '30px', height: '30px', marginRight: '8px' }}
                        />
                        <Typography variant="body1">All Things Sports</Typography>
                    </div>
                </div>
                <Typography
                    variant="h6"
                    noWrap
                    className={clsx(classes.title, {
                        [classes.hide]: !open,
                    })}
                >
                    {' '}
                </Typography>
                <Typography variant="body1">{name}</Typography>
            </Toolbar>
        </AppBar>
    );
}

const mapStateToProps = (state: LayoutRootType) => {
    return {
        name: state.authState.name,
        open: state.layoutState.sidbarOpened,
    };
};

const dispatchToProps = {
    toggleOpen: toggleSidebar,
};

const ConnectedNavbar = connect(mapStateToProps, dispatchToProps)(Navbar);

export const NavbarContainer = () => (
    <DynamicModuleLoader modules={[LayoutModule]}>
        <ConnectedNavbar></ConnectedNavbar>
    </DynamicModuleLoader>
);
