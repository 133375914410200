import React from 'react';
import { Icon } from 'semantic-ui-react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FooterContainer(props: React.PropsWithChildren<any>) {
    return (
        <React.Fragment>
            <div style={{ paddingTop: '1.5rem' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'white',
                        backgroundColor: '#424242',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                    }}
                >
                    <div style={{ display: 'flex', paddingBottom: '1rem' }}>
                        <div>
                            <a
                                href="https://twitter.com/OfficialATS_"
                                style={{ color: 'white' }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon name="twitter" size="large" />
                                {/* @OfficialATS_ */}
                            </a>
                        </div>
                        <div style={{ paddingLeft: '8px' }}>
                            <a
                                style={{ color: 'white' }}
                                href="https://discord.gg/uE45kXuAUv"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon name="discord" size="large" />
                                {/* ATS */}
                            </a>
                        </div>
                    </div>
                    Copyright © 2023 All Things Sports. All rights reserved.
                </div>
            </div>
        </React.Fragment>
    );
}
