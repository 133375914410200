import React, { useEffect, useState } from 'react';
import { DataService } from '../../shared/services/data.service';
import { StatsTableComponent } from './statsTableComponent';

export function PassingStatsTable() {
    const [test, setTest] = useState([]);

    const columns = [
        { selector: (row: any) => row.player_id, name: 'PlayerID' },
        { selector: (row: any) => row.game_id, name: 'GameID' },
        { selector: (row: any) => row.date, name: 'Date' },
        { selector: (row: any) => row.season, name: 'Season' },
        { selector: (row: any) => row.week, name: 'Week' },
        { selector: (row: any) => row.player, name: 'Player' },
        { selector: (row: any) => row.team, name: 'Team' },
        { selector: (row: any) => row.pass_yards, name: 'Pass Yards' },
        { selector: (row: any) => row.completions, name: 'Completions' },
    ];

    useEffect(() => {
        DataService.genericGETI('/stats/passing').then(i => {
            setTest(i.data);
        });
    }, []);

    if (test.length > 0) {
        return (
            <StatsTableComponent tableName="Passing Stats" tableData={test} columns={columns} searchField="player" />
        );
    } else return <>Loading Receiving Stats Table...</>;
}
