import { createTheme } from '@material-ui/core';
import rootTheme from '../../../../shared/config/theme';

export const sidebarTheme = createTheme({
    ...rootTheme,
    palette: {
        text: {
            primary: 'white',
        },
    },
    props: {
        MuiListItem: {
            className: 'sidebar-list-item',
        },
        MuiListItemText: {
            className: 'sidebar-list-item-text',
        },
        MuiListItemIcon: {
            className: 'sidebar-list-item-icon',
        },
    },
});
