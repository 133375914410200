export default class Globals {
    static apiUrl = process.env.REACT_APP_OPTI_API_URL;
    static apiUrlI = process.env.REACT_APP_ATS_API_URL;

    static AdditionalFilters = [
        {
            sport: 'NFL',
            filterName: 'Flex',
        },
    ];

    static MaxPositions = {
        NFL: {
            QB: 1,
            WR: 4,
            TE: 2,
            RB: 3,
            D: 1,
        },
    };

    static AdminUsers = ['tcraner', 'gtlopez', 'yates.anthonyj@gmail.com', 'ATS_Admin', 'AllCashATS'];
}
