import axios from 'axios';
import Globals from '../../shared/globals';

export enum APIPath {
    getNbaFdPlayers = '/fd/nba/getPlayers',
    getNbaDkPlayers = '/dk/nba/getPlayers',
    getNflFdPlayers = '/fd/nfl/getPlayers',
    getNflDkPlayers = '/dk/nfl/getPlayers',
}

// const { response }: { response: AxiosResponse } = error;
// if (response.status === 401) {
//     this.props.logout();
// }

export const DataService = {
    genericGET: function(path: string): Promise<any> {
        const config = {
            headers: {
                withCredentials: true,
                Authorization: `Bearer ${localStorage.getItem('wp_tok')}`,
            },
        };

        return axios.get(Globals.apiUrl + path, config);
    },
    genericGETI: function(path: string): Promise<any> {
        return axios.get(Globals.apiUrlI + path);
    },
    genericPOST: function(path: string, dto: any): Promise<any> {
        const config = {
            headers: {
                withCredentials: true,
                Authorization: `Bearer ${localStorage.getItem('wp_tok')}`,
            },
        };

        return axios.post(Globals.apiUrl + path, dto, config);
    },
};
