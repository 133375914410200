import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthMainStateType } from '../shared/auth/types';
import * as actionTypes from '../shared/auth/actions/actions';
import { NavbarContainer } from './layout/home/containers/navbar';
import { MainContainer } from './layout/home/components/maincontainer';
import { CssBaseline } from '@material-ui/core';
import { useStyles } from './layout/home/styles/layout';
import { SidebarContainer } from './layout/home/containers/sidebar';
import { Optimizer } from '../feature/optimizer/optimizer';
import { FooterContainer } from './layout/home/components/footer';
import { DashboardContainer } from '../feature/dashboard/dashboard';
import { StatsComponent } from '../feature/stats/statsComponent';
import { NotFound } from '../shared/components/notFound';
import Globals from '../shared/globals';

type Props = {
    isLoggedIn: boolean;
    name: string;
    logout: typeof actionTypes.fetchLogoutAsync.request;
};

function RedirectComponent() {
    window.location.replace('https://opti.officialats.com/');
    return null;
}

const Home = (props: Props) => {
    const classes = useStyles();
    const { isLoggedIn, name } = props;

    return isLoggedIn ? (
        process.env.REACT_APP_ENVIRONMENT === 'development' && !Globals.AdminUsers.includes(name) ? (
            <RedirectComponent />
        ) : (
            <div className={classes.root}>
                <CssBaseline />
                <NavbarContainer />
                <SidebarContainer />
                <MainContainer>
                    <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/" element={<Navigate to="nfl/draftkings" />} />
                        <Route path="/:sport/:dfsSite" element={<Optimizer />} />
                        {/* TODO make admin path it's own router and check permissions on it */}
                        <Route path="/stats" element={<StatsComponent />} />
                        <Route path="/admin" element={<DashboardContainer />} />
                    </Routes>
                </MainContainer>
                <FooterContainer />
            </div>
        )
    ) : (
        <Navigate to={{ pathname: '/' }} />
    );
};

const mapStateToProps = (state: AuthMainStateType) => {
    return {
        isLoggedIn: state.authState.isLoggedIn,
        name: state.authState.name,
    };
};

const dispatchToProps = {
    logout: actionTypes.fetchLogoutAsync.request,
};

export const HomeContainer = connect(mapStateToProps, dispatchToProps)(Home);
