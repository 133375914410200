import React, { useEffect, useState } from 'react';
import { DataService } from '../../shared/services/data.service';
import { StatsTableComponent } from './statsTableComponent';

export function ReceivingStatsTable() {
    const [test, setTest] = useState([]);

    const columns = [
        { selector: (row: any) => row.player_id, name: 'PlayerID' },
        { selector: (row: any) => row.game_id, name: 'GameID' },
        { selector: (row: any) => row.date, name: 'Date' },
        { selector: (row: any) => row.season, name: 'Season' },
        { selector: (row: any) => row.week, name: 'Week' },
        { selector: (row: any) => row.player, name: 'Player' },
        { selector: (row: any) => row.team, name: 'Team' },
        { selector: (row: any) => row.receptions, name: 'Receptions' },
        { selector: (row: any) => row.receiving_yards, name: 'Receiving Yards' },
    ];

    useEffect(() => {
        DataService.genericGETI('/stats/receiving').then(i => {
            setTest(i.data);
        });
    }, []);

    if (test.length > 0) {
        return (
            <StatsTableComponent tableName="Receiving Stats" tableData={test} columns={columns} searchField="player" />
        );
    } else return <>Loading Receiving Stats Table...</>;
}
