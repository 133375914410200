import * as actionType from '../actions/actions';
import { UserState } from '../types';
import { getType } from 'typesafe-actions';

const initialState: UserState = {
    isLoggedIn: localStorage.getItem('displayName') !== null,
    name: localStorage.getItem('displayName') || '',
    message: '',
};

const reducer = (state = initialState, action: actionType.authActionTypes): UserState => {
    switch (action.type) {
        case getType(actionType.fetchLoginAsync.success):
            return action.payload;
        case getType(actionType.fetchLoginAsync.failure):
            return { isLoggedIn: false, name: '', message: action.payload };
        case getType(actionType.fetchLogoutAsync.success):
            return { ...state, isLoggedIn: false, name: '' };
        default:
            return state;
    }
};

export default reducer;
