import React, { useState } from 'react';
import { Typography, Button, Container, CssBaseline, TextField, MuiThemeProvider } from '@material-ui/core';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import { fetchLoginAsync } from '../../../shared/auth/actions/actions';
import { AuthModule } from '../../../shared/auth/module/module';
import { useStyles } from '../styles/login';
import { logintheme } from '../styles/loginTheme';
import atsblklogo from '../../../static/atsblklogo.png';
import { AuthState } from '../../../shared/auth/types';
import { Message } from 'semantic-ui-react';

interface LoginProps {
    isLoggedIn: boolean;
    name: string | null;
    message: string;
    login: typeof fetchLoginAsync.request;
}

function Login(props: LoginProps) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const classes = useStyles();
    return (
        <React.Fragment>
            <MuiThemeProvider theme={logintheme}>
                <Container component="main" maxWidth="xs">
                    {process.env.REACT_APP_ENVIRONMENT === 'development' ? (
                        <Message info>
                            <Message.Header>This site has moved!</Message.Header>
                            <p>
                                This site is now only for testing purposes. Once signed in, you will be redirected to
                                the new site found <a href="https://opti.officialats.com/">here</a>.
                                <br />
                                <br />
                                For further assistance, please contact an ATS administrator.
                            </p>
                        </Message>
                    ) : (
                        <></>
                    )}
                    <CssBaseline />
                    <div className={classes.paper}>
                        <div>
                            <img style={{ width: '100px' }} src={atsblklogo} alt="ATS Logo" />
                        </div>
                        <Typography variant="body1">Login using your existing ATS account.</Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="off"
                                autoFocus
                                onChange={e => setUsername(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <div style={{ color: 'red', height: '20px' }}>{props.message}</div>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="medium"
                                className={classes.submit}
                                onClick={() => props.login({ username, password })}
                            >
                                Sign In
                            </Button>
                            {/* <Grid container alignItems="center">
                                <Grid item xs={12} sm container justifyContent="center">
                                    <Link href="#" variant="body2" color="textPrimary">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm container justifyContent="center">
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="secondary" />}
                                        label="Remember me"
                                    />
                                </Grid>
                            </Grid> */}
                        </form>
                    </div>
                </Container>
            </MuiThemeProvider>
        </React.Fragment>
    );
}

const mapStateToProps = (state: AuthState) => {
    return {
        isLoggedIn: state.authState.isLoggedIn,
        name: state.authState.name,
        message: state.authState.message,
    };
};

const dispatchToProps = {
    login: fetchLoginAsync.request,
};

const ConnectedLogin = connect(mapStateToProps, dispatchToProps)(Login);

export const LoginContainer = () => (
    <DynamicModuleLoader modules={[AuthModule]}>
        <ConnectedLogin></ConnectedLogin>
    </DynamicModuleLoader>
);
