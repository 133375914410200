import React from 'react';
import { useParams } from 'react-router-dom';
import { InputOptimizerContainer } from './components/inputOptimizerComponent';
import { NotFound } from '../../shared/components/notFound';
import './optimizer.scss';

export function Optimizer() {
    const { dfsSite, sport } = useParams();
    return (
        <React.Fragment>
            {(dfsSite !== 'draftkings' && dfsSite !== 'fanduel') || (sport !== 'nfl' && sport !== 'nba') ? (
                <NotFound />
            ) : (
                <InputOptimizerContainer />
            )}
        </React.Fragment>
    );
}
