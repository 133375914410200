import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function LoadingComponent() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 48px)',
            }}
        >
            <ThreeDots height="80" width="80" color="#424242" />
            Please wait...
        </div>
    );
}
