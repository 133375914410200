import axios from 'axios';
import * as dataTypes from '../types';

export function login(username: string, password: string) {
    const userDetail: dataTypes.LoginDetails = {
        username,
        password,
    };
    // TODO pull from globals config
    return axios.post('https://www.officialats.com/wp-json/jwt-auth/v1/token', userDetail);
}
