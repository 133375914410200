import React from 'react';
import { ReceivingStatsTable } from './receivingStatsTableComponent';
import { PassingStatsTable } from './passingStatsTableComponent';

// This is kinda like the wrapper/landing page for stats tables (NFL for now)
export function StatsComponent() {
    return (
        <React.Fragment>
            <b>NFL Stats Tables</b>
            <div>
                <ReceivingStatsTable />
                <PassingStatsTable />
            </div>
        </React.Fragment>
    );
}
