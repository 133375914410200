import React from 'react';
import { Input, Table, Icon } from 'semantic-ui-react';

interface StatsTableProps {
    tableName: string;
    tableData: any[];
    columns: any[];
    searchField: string;
}

interface StatsTableState {
    tableDataClean: any[];
    tableData: any[];
    columns: any[];
    searchInput: string;
}

export class StatsTableComponent extends React.Component<StatsTableProps, StatsTableState> {
    constructor(props: StatsTableProps) {
        super(props);

        this.state = {
            tableDataClean: this.props.tableData,
            tableData: this.props.tableData,
            columns: this.props.columns,
            searchInput: '',
        };
    }

    handleSearch(e: any) {
        const searchInput = e.target.value;
        this.setState({ searchInput });
    }

    clearSearch() {
        this.setState({ searchInput: '' });
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Title */}
                    <div>
                        <b>{this.props.tableName}</b>
                    </div>
                    {/* Toolbar/Menu below */}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0 0 10px 0',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <Input
                                    icon={
                                        this.state.searchInput === '' ? (
                                            <Icon name="search" />
                                        ) : (
                                            <Icon name="x" onClick={() => this.clearSearch()} link />
                                        )
                                    }
                                    placeholder="Search..."
                                    value={this.state.searchInput}
                                    onChange={e => this.handleSearch(e)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Table */}
                    <div
                        style={{
                            overflow: 'scroll',
                            height: 500,
                        }}
                    >
                        <Table unstackable fixed striped collapsing>
                            <Table.Header>
                                <Table.Row>
                                    {this.state.columns.map((column: any) => {
                                        return <Table.HeaderCell key={column.name}>{column.name}</Table.HeaderCell>;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            {this.state.tableData.filter(row =>
                                row[this.props.searchField]
                                    .toLowerCase()
                                    .includes(this.state.searchInput.toLowerCase()),
                            ).length > 0 ? (
                                <Table.Body>
                                    {this.state.tableData
                                        .filter(row =>
                                            row[this.props.searchField]
                                                .toLowerCase()
                                                .includes(this.state.searchInput.toLowerCase()),
                                        )
                                        .map((data: any, dataIndex: number) => {
                                            return (
                                                <Table.Row key={dataIndex}>
                                                    {this.state.columns.map((column: any, columnIndex: number) => {
                                                        return (
                                                            <Table.Cell key={columnIndex}>
                                                                {column.selector(data)}
                                                            </Table.Cell>
                                                        );
                                                    })}
                                                </Table.Row>
                                            );
                                        })}
                                </Table.Body>
                            ) : (
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>No results.</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            )}
                        </Table>
                    </div>
                    <div>
                        {`Showing 1 to ${this.state.tableData
                            .filter(row =>
                                row[this.props.searchField]
                                    .toLowerCase()
                                    .includes(this.state.searchInput.toLowerCase()),
                            )
                            .length.toLocaleString()} of 
                        ${this.state.tableData.length.toLocaleString()} entries`}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
