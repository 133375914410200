import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../shared/auth/actions/actions';
import { AuthState } from '../shared/auth/types';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { LoginContainer } from '../feature/login/containers/loginContainer';
import { Routes, Route, Navigate } from 'react-router-dom';
import Globals from '../shared/globals';

const useStyles = makeStyles({
    container: {
        background: '#ecf0f2',
        width: '100%',
        minHeight: '100vh',
        display: 'grid',
        margin: 0,
        placeItems: 'center center',
    },
});

type Props = {
    isLoggedIn: boolean;
    name: string;
    login: typeof actionTypes.fetchLoginAsync.request;
};

function RedirectComponent() {
    window.location.replace('https://opti.officialats.com/');
    return null;
}

const Landing = (props: Props) => {
    const { isLoggedIn, name } = props;
    const classes = useStyles();

    return !isLoggedIn ? (
        <div className={classes.container}>
            <CssBaseline />
            <Routes>
                <Route path="/" element={<Navigate to="login" />} />
                <Route path="login" element={<LoginContainer />} />
            </Routes>
        </div>
    ) : process.env.REACT_APP_ENVIRONMENT === 'development' && !Globals.AdminUsers.includes(name) ? (
        <RedirectComponent />
    ) : (
        <Navigate to={{ pathname: 'app' }} />
    );
};

const mapStateToProps = (state: AuthState) => {
    return {
        isLoggedIn: state.authState.isLoggedIn,
        name: state.authState.name,
    };
};

const dispatchToProps = {
    login: actionTypes.fetchLoginAsync.request,
};

export const LandingContainer = connect(mapStateToProps, dispatchToProps)(Landing);
