import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HomeContainer } from './routes/home';
import { LandingContainer } from './routes/landing';

const App: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path="*" element={<LandingContainer />} />
                <Route path="app/*" element={<HomeContainer />} />
            </Routes>
        </>
    );
};

export default App;
